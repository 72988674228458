@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("./fonts/Inter-roman.var.woff2") format("woff2");
}
@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("./fonts/Inter-italic.var.woff2") format("woff2");
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./fonts/fa-brands-400.woff2") format("woff2");
}
.fab {
  font-family: "Font Awesome 5 Brands";
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/fa-regular-400.woff2") format("woff2");
}
.far {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

@font-face {
  font-family: "Linearicons";
  src: url("./fonts/Linearicons.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --arthur-font: "Inter var", sans-serif;

  --arthur-1-cor-bkg: #0f223f;
  --arthur-1-cor-txt: #dde6ed;
  --arthur-1-cor-scd: #4e7494;
  --arthur-1-cor-tcr: #9db2bf;
}

::selection {
  background: rgba(0, 0, 0, 0.4);
}

body {
  background: var(--arthur-1-cor-bkg);
  color: var(--arthur-1-cor-txt);
  overflow: auto;
  font-weight: 300;
  font-size: 14px;
}

a {
  transition: all 0.5s ease;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--arthur-font);
  line-height: 160%;
}

.container-fluid {
  overflow: hidden;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 120%;
}

img {
  max-width: 100%;
}

header {
  position: relative;
  width: 40%;
  max-width: 360px;
  height: 100vh;
  display: block;
  float: left;
  height: 50vh;
  padding: 100px 0;
  position: fixed;
  font-size: 16px;
  z-index: 200;
}
header h1 a {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1.3px;
  color: var(--arthur-1-cor-txt);
  line-height: 120%;
  text-decoration: none;
}
header h3 {
  color: var(--arthur-1-cor-scd);
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 30px;
}

header p {
  color: var(--arthur-1-cor-tcr);
}

header nav {
  margin: 30px 0 0;
}
header nav ul {
  list-style: none;
  display: table;
}
header nav ul li {
  margin: 15px 0;
}
header nav ul li a {
  position: relative;
  display: block;
  padding-left: 45px;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--arthur-1-cor-scd);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  cursor: pointer;
}
header nav ul li a:before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  content: "";
  background: var(--arthur-1-cor-scd);
  width: 35px;
  transition: all 0.3s ease;
}
header nav ul li a:hover,
header nav ul li a.ativo {
  color: var(--arthur-1-cor-txt);
  padding-left: 75px;
}
header nav ul li a:hover:before,
header nav ul li a.ativo:before {
  background: var(--arthur-1-cor-txt);
  width: 65px;
}
header .menu,
header .sobreposicao {
  display: none;
}

header img {
  border-radius: 50%;
  border: 2px solid var(--arthur-1-cor-scd);
  width: 100%;
  max-width: 180px;
  height: auto;
  margin: 0 auto 10px;
  display: table;
}

main {
  position: relative;
  float: right;
  width: 58%;
  max-width: 680px;
  font-size: 16px;
  z-index: 100;
  color: var(--arthur-1-cor-tcr);
}

/*
main:before { content: ""; position:fixed; top:-1px; left: 0; width:100%; height:90px; background: linear-gradient(0deg, rgba(15,34,63,0) 0%, rgba(15,34,63,1) 90%); z-index: 910; }
main:after { content: ""; position:fixed; bottom:-1px; left: 0; width:100%; height:90px; background: linear-gradient(0deg, rgba(15,34,63,1) 10%, rgba(15,34,63,0) 100%); z-index: 920; }
*/

main h2 {
  position: relative;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 30px;
  text-align: right;
  display: flex;
  align-items: center;
  color: var(--arthur-1-cor-scd);
}
main h2:before {
  content: "";
  position: relative;
  flex: auto 1;
  height: 1px;
  left: 0;
  top: 50%;
  margin-left: 15px;
  background: var(--arthur-1-cor-scd);
  order: 2;
}
main p {
  margin-bottom: 15px;
}

main section {
  padding: 100px 0 0; /* min-height:86vh; */
}
main section:last-child {
  margin-bottom: 30px;
}

main section#habilidades .row {
  padding: 0 15px;
}
main section#habilidades h2 {
  margin-bottom: 60px;
}

.item {
  color: var(--arthur-1-cor-tcr);
  font-size: 14px;
  margin: 0 0;
  border-radius: 10px;
  padding: 30px 15px;
  border-top: 1px solid rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition: all 0.5s ease;
}
.item:hover {
  background: rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.item h3 {
  color: var(--arthur-1-cor-txt);
  font-size: 24px;
  font-weight: 600;
}
.item h4 {
  color: var(--arthur-1-cor-scd);
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0 10px;
}
.item h5 {
  color: var(--arthur-1-cor-scd);
  font-size: 18px;
  font-weight: 600;
  margin: 5px 0 0;
  text-align: right;
  text-transform: uppercase;
}
.item .selos {
  list-style: none;
  margin: -10px 0 0;
}
.item .selos li {
  display: inline-block;
  margin: 10px 10px 0 0;
  border-radius: 15px;
  background: none;
  color: var(--arthur-1-cor-scd);
  border: 1px solid var(--arthur-1-cor-scd);
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  cursor: default;
  transition: all 0.2s ease;
}
.item .selos li:hover {
  background: var(--arthur-1-cor-scd);
  color: var(--arthur-1-cor-bkg);
}
.item img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  display: table;
}

main section#sobre-mim em {
  color: var(--arthur-1-cor-txt);
  font-weight: 400;
}
#sobre-mim a {
  position: relative;
  font-weight: 600;
  color: var(--arthur-1-cor-txt);
  text-decoration: none;
  padding: 0 2px;
  margin: 0 -2px;
}
#sobre-mim a:not(.btn):before {
  position: absolute;
  content: "";
  height: 10px;
  background: var(--arthur-1-cor-scd);
  width: 0;
  bottom: 0px;
  left: 0;
  z-index: -1;
  opacity: 0.5;
  transition: all 0.2s ease;
}
#sobre-mim a:not(.btn):hover:before {
  width: 100%;
}

main section#sobre-mim p:last-child {
  margin-bottom: 0;
}

#habilidades h4 {
  text-align: center;
  color: var(--arthur-1-cor-scd);
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
}
#habilidades ul.icones {
  list-style: none;
  display: table;
  margin: 0 auto 30px;
  text-align: center;
}
#habilidades ul.icones li {
  display: inline-block;
  padding: 15px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: var(--arthur-1-cor-tcr);
  transition: all 0.5s ease;
}
#habilidades ul.icones li:hover {
  color: var(--arthur-1-cor-txt);
}
#habilidades ul.icones li i {
  font-size: 48px;
  height: 48px;
  line-height: 48px;
  margin: 0 auto 5px;
  opacity: 1;
  transition: all 0.5s ease;
  color: var(--arthur-1-cor-scd);
  opacity: 0.5;
  display: block;
}
#habilidades ul.icones li:hover i {
  opacity: 1;
  color: var(--arthur-1-cor-txt);
}

.aa_icons {
  width: 48px;
  height: 48px;
  display: block;
  background: var(--arthur-1-cor-scd);
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center;
}
#habilidades ul.icones li:hover i.aa_icons {
  background: var(--arthur-1-cor-txt);
}

.aa_icons.figma {
  mask-image: url("./img/aa_figma.svg");
}
.aa_icons.xd {
  mask-image: url("./img/aa_xd.svg");
}
.aa_icons.ps {
  mask-image: url("./img/aa_ps.svg");
}
.aa_icons.ai {
  mask-image: url("./img/aa_ai.svg");
}
.aa_icons.git {
  mask-image: url("./img/aa_git.svg");
}
.aa_icons.tortoise {
  mask-image: url("./img/aa_tortoise.svg");
}
.aa_icons.code {
  mask-image: url("./img/aa_code.svg");
}
.aa_icons.sublime {
  mask-image: url("./img/aa_sublime.svg");
}
.aa_icons.macos {
  mask-image: url("./img/aa_macos.svg");
}
.aa_icons.user {
  mask-image: url("./img/aa_user.svg");
}

#projetos a {
  text-decoration: none;
  display: block;
}
#projetos a span {
  position: relative;
  font-weight: 600;
  font-size: 12px;
  background-color: none;
  color: var(--arthur-1-cor-scd);
  text-decoration: none;
  padding: 7px;
  margin: 0 0 15px;
  display: block;
  text-align: center;
  border: 1px solid var(--arthur-1-cor-scd);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-transform: uppercase;
  transition: all 0.2s ease;
}
#projetos a:hover span {
  background: #fff;
  border-color: #fff;
  color: var(--arthur-1-cor-bkg);
}

#contato {
  min-height: 100vh;
}
#contato form {
  position: relative;
  margin: 15px 0 60px;
}
#contato label {
  display: block;
  color: #000;
  font-weight: 400;
  position: absolute;
  top: 10px;
  left: 25px;
  cursor: text;
  font-size: 14px;
  display: flex;
  transition: all 0.5s ease;
}
#contato label i {
  margin-right: 5px;
  color: #c2c2c2;
  font-size: 24px;
  display: inline-flex;
}
#contato label i.aa_icons {
  background: #c2c2c2;
  width: 24px;
  height: 24px;
}
#contato label span {
  display: inline-flex;
  align-items: center;
}
#contato fieldset {
  border: none;
  position: relative;
}

#contato input,
#contato textarea {
  background: #f9f9f9;
  display: block;
  width: 100%;
  padding: 10px 15px 10px 46px;
  color: #000;
  appearance: none;
  outline: none;
  border-radius: 3px;
  border: 1px solid #aaa;
  font-size: 14px;
  transition: all 0.5s ease;
  box-shadow: inset 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
}
#contato input:focus,
#contato textarea:focus {
  box-shadow: none;
  border: 1px solid #c5c8ce;
  background: #fff;
}
#contato input::placeholder,
#contato textarea::placeholder {
  color: #000;
}
#contato textarea {
  resize: none;
}

#contato input:-webkit-autofill {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
#contato input:-moz-autofill {
  -moz-text-fill-color: #000;
  -moz-box-shadow: 0 0 0px 1000px white inset;
}
#contato input:-o-autofill {
  -o-text-fill-color: #000;
  -o-box-shadow: 0 0 0px 1000px white inset;
}
#contato input:-khtml-autofill {
  -khtml-text-fill-color: #000;
  -khtml-box-shadow: 0 0 0px 1000px white inset;
}
#contato input:focus:-webkit-autofill {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
#contato input:focus:-moz-autofill {
  -moz-text-fill-color: #000;
  -moz-box-shadow: 0 0 0px 1000px white inset;
}
#contato input:focus:-o-autofill {
  -o-text-fill-color: #000;
  -o-box-shadow: 0 0 0px 1000px white inset;
}
#contato input:focus:-khtml-autofill {
  -khtml-text-fill-color: #000;
  -khtml-box-shadow: 0 0 0px 1000px white inset;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

#contato form .row {
  transition: all 0.5s ease;
}
#contato form.ativo .row {
  opacity: 0.1;
}
#contato form.ativo:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  content: "\ebda";
  font-family: "Linearicons";
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  text-align: center;
  color: var(--arthur-1-cor-scd);
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

#contato button {
  background: radial-gradient(
    100% 100% at 100% 0,
    var(--arthur-1-cor-tcr) 0,
    var(--arthur-1-cor-scd) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.4) 0 2px 4px, rgba(0, 0, 0, 0.3) 0 7px 13px -3px,
    rgba(0, 0, 0, 0.5) 0 -3px 0 inset;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 10px 30px;
  transition: box-shadow 0.15s, transform 0.15s;
  font-size: 18px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
}
#contato form:not(.ativo) button:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0 4px 8px, rgba(0, 0, 0, 0.3) 0 7px 13px -3px,
    rgba(0, 0, 0, 0.4) 0 -3px 0 inset;
  transform: translateY(-2px);
}
#contato form:not(.ativo) button:active {
  box-shadow: var(--arthur-1-cor-bkg) 0 3px 7px inset;
  transform: translateY(2px);
}

#contato textarea {
  min-height: 90px;
}

#contato a {
  position: relative;
  color: var(--arthur-1-cor-scd);
  text-decoration: none;
  padding: 0 2px;
  margin: 0 -2px;
}
#contato a:hover {
  color: var(--arthur-1-cor-txt);
}
#contato a:before {
  position: absolute;
  content: "";
  height: 10px;
  background: var(--arthur-1-cor-scd);
  width: 0;
  bottom: 2px;
  left: 0;
  z-index: -1;
  opacity: 0.5;
  transition: all 0.2s ease;
}
#contato a:hover:before {
  width: 100%;
}
#contato ul {
  list-style: none;
  margin: 0 auto;
  text-align: center;
}
#contato ul li {
  margin: 0 15px 10px;
  display: inline-block;
}
#contato ul li a {
  display: flex;
}
#contato ul li i {
  margin-right: 7px;
  font-size: 24px;
  display: inline-flex;
}
#contato ul li span {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  word-break: break-all;
  line-height: 110%;
  color: var(--arthur-1-cor-txt);
}

#contato .col-12,
#contato .col-6 {
  margin: 25px 0 0;
}

#contato h3 {
  color: var(--arthur-1-cor-txt);
  font-size: 24px;
  font-weight: 600;
}
#contato h4 {
  color: var(--arthur-1-cor-scd);
  font-size: 18px;
  margin: 5px 0 0;
  font-weight: 500;
}

#creditos {
  font-size: 80%;
  color: var(--arthur-1-cor-scd);
  max-width: 610px;
}
#creditos em {
  font-weight: 400;
  color: var(--arthur-1-cor-tcr);
  word-break: keep-all;
}
#creditos .aa_icons {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: var(--arthur-1-cor-txt);
}

footer {
  position: fixed;
  bottom: 0;
  padding: 0 0 30px;
  z-index: 300;
  font-size: 12px;
  color: var(--arthur-1-cor-scd);
}
footer a {
  text-decoration: none;
  font-weight: 400;
  color: var(--arthur-1-cor-scd);
}
footer a:hover {
  color: var(--arthur-1-cor-txt);
}
footer ul {
  list-style: none;
  margin: 0 0 15px;
}
footer ul li {
  display: inline-block;
  margin-right: 10px;
  color: var(--arthur-1-cor-scd);
  font-size: 24px;
}
footer ul li a {
  display: block;
  font-weight: 400;
}
footer ul li a span {
  display: none;
}

.mouse-brilho {
  position: absolute;
  transition: all 0.2s ease-out;
  width: 800px;
  height: 800px;
  background: none;
  background: radial-gradient(
    circle,
    rgba(130, 197, 238, 0.15) 0%,
    rgba(130, 197, 238, 0) 70%
  );
  opacity: 1;
  z-index: 1;
}

@media (max-height: 776px) {
  main:before,
  main:after {
    display: none;
  }
  header,
  main section {
    padding-top: 30px;
  }
  /*header {
    position: absolute;
  }*/
  header img {
    max-width: 150px;
  }
}

@media (max-height: 767px) {
  footer {
    position: absolute;
  }
  header img {
    max-width: 120px;
  }
  header h3 {
    margin: 0 0 15px;
  }
  header nav {
    margin: 15px 0 0;
  }
  header nav ul li {
    margin: 10px 0;
  }
}

@media (max-width: 1024px) {
  main section#experiencia .row > div {
    display: block;
    width: 100%;
  }
  .item h5 {
    text-align: left;
    margin: 0 0 5px;
  }
}

@media (max-width: 991px) {
  body {
    font-size: 16px;
  }
  header {
    font-size: 12px;
    width: 24%;
  }
  header h1 a {
    font-size: 20px;
    letter-spacing: -1px;
  }
  header h3 {
    font-size: 18px;
    margin: 0 0 15px;
  }
  header nav ul li a {
    font-size: 12px;
  }
  main {
    width: 67%;
    font-size: 16px;
  }

  footer {
    font-size: 10px;
  }

  /* #habilidades ul.icones li i{ font-size:38px; } */
}

@media (max-width: 767px) {
  .mouse-brilho {
    display: none;
  }

  header {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: 80px;
    padding: 15px 30px 0;
    background: var(--arthur-1-cor-bkg);
    z-index: 400;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  }
  header p {
    display: none;
  }
  header nav {
    position: fixed;
    top: 0;
    margin: 0;
    left: -320px;
    width: 266px;
    padding: 15px;
    height: 100vh;
    background: var(--arthur-1-cor-bkg);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
    z-index: 300;
    transition: all 0.2s ease;
  }
  header.ativo nav {
    left: 0;
  }
  header nav ul {
    margin-top: 30px;
  }
  header nav ul li a {
    font-size: 16px;
  }
  header h1 a {
    font-size: 22px;
  }

  header .menu {
    display: table;
    position: absolute;
    top: 23px;
    right: 30px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 4px;
    z-index: 200;
    background: var(--arthur-1-cor-bkg);
    border: 1px solid var(--arthur-1-cor-scd);
  }
  header .menu:focus {
    outline: none;
  }
  header .menu span {
    display: block;
    position: absolute;
    top: 14px;
    left: 5px;
    right: 5px;
    height: 3px;
    background: var(--arthur-1-cor-scd);
  }
  header .menu span::before,
  header .menu span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    background: var(--arthur-1-cor-scd);
    content: "";
  }
  header .menu span::before {
    top: -7px;
  }
  header .menu span::after {
    bottom: -7px;
  }
  nav header .menu {
    background-color: var(--arthur-1-cor-bkg);
  }
  nav.fixed header .menu {
    background-color: var(--arthur-1-cor-bkg);
  }
  header .menu span {
    transition: all 0 0.2s;
  }
  header .menu span::before,
  header .menu span::after {
    transition-duration: 0.2s, 0.2s;
    transition-delay: 0.2s, 0;
  }
  header .menu span::before {
    transition-property: top, transform;
  }
  header .menu span::after {
    transition-property: bottom, transform;
  }
  header.ativo .menu {
    background-color: var(--arthur-1-cor-bkg);
  }
  header.ativo .menu span {
    background: none;
  }
  header.ativo .menu span::before {
    top: 0;
    transform: rotate(45deg);
  }
  header.ativo .menu span::after {
    bottom: 0;
    transform: rotate(-45deg);
  }
  header.ativo .menu span::before,
  header.ativo .menu span::after {
    transition-delay: 0s, 0.2s;
    background-color: var(--arthur-1-cor-scd) !important;
  }
  header .sobreposicao {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #0c172b;
    opacity: 0;
    display: block;
    visibility: hidden;
    z-index: 100;
    transition: all 0.2s ease;
  }
  header.ativo .sobreposicao {
    opacity: 0.9;
    visibility: visible;
  }

  header img {
    float: left;
    margin: 0 10px 0 0;
    width: 56px;
  }

  main {
    width: 100%;
    padding-top: 80px;
  }
  main section {
    padding: 30px 0;
  }
  main section:last-child {
    padding-bottom: 15px;
    margin-bottom: 0;
  }
  main section .row > div {
    display: block;
    width: 100%;
  }
  main h2 {
    margin-bottom: 30px;
  }
  main section#habilidades h2 {
    margin-bottom: 30px;
  }

  /* main:before { top:80px; height:50px; } */

  footer {
    position: relative;
    bottom: auto;
    clear: both;
    display: block;
    padding-bottom: 15px;
    text-align: center;
    font-size: 14px;
  }
  footer ul {
    margin: 0;
  }
  footer ul li {
    font-size: 38px;
    margin: 0 15px;
  }

  #sobre-mim p {
    z-index: 915;
    line-height: 130%;
  }

  #habilidades h4 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 18px;
  }
  #habilidades h4 br {
    display: none;
  }
  #habilidades ul.icones {
    margin: 0 auto 15px;
    text-align: center;
    width: 100%;
  }
  #habilidades ul.icones li {
    width: 33.3%;
    padding: 15px 0;
  }
  /* #habilidades ul.icones li i{ font-size:54px; } */
  #habilidades > div > div {
    padding: 0;
  }

  #projetos a {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .item {
    background: rgba(0, 0, 0, 0.15);
    border-top: 1px solid rgba(255, 255, 255, 0.07);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 30px;
  }

  #contato {
    min-height: 0;
  }
  #contato ul {
    text-align: left;
  }
  #contato ul li {
    margin-left: 0;
  }
  #contato fieldset {
    margin: 15px 0 0;
  }
  #contato button {
    width: 100%;
  }
  #contato .col-12,
  #contato .col-6 {
    margin: 15px 0 0;
  }
}

@media (max-height: 545px) {
  footer {
    position: relative;
    bottom: auto;
    clear: both;
    display: block;
  }
}

@media (max-width: 364px) {
  header img {
    display: none;
  }
}
